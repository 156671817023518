.yarl__thumbnails_thumbnail_active {
    border-width: 4px;
    border-color: #0A6FB8;
  }
.yarl__toolbar {
  position: absolute;
  top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px 0 0 8px;
  color: #dbd9d9;
  font-weight: bold;
  font-size: x-large;
  padding: 1rem;
}


@media (max-width: 2000px) {
  .yarl__toolbar {
    top: 10%;
    font-size: large;
    padding: 0.5rem;
    gap: 0.2vh;
  }
  .yarl__toolbar svg {
    width: 2rem;
    height: 2rem;
  }
}

@media (max-width: 987px) {
  .yarl__toolbar {
    top: 10%;
    font-size: large;
    padding: 0.5rem;
    gap: 0.2vh;
  }
  .yarl__toolbar svg {
    width: 2rem;
    height: 2rem;
  }
}

@media (max-width: 784px) {
  .yarl__toolbar {
    top: 12%;
    font-size: medium;
    padding: 0.3rem;
    gap: 0.2vh;
  }
  .yarl__toolbar svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media (max-width: 688px) {
  .yarl__toolbar {
    top: 12%;
    font-size: medium;
    padding: 0.3rem;
    gap: 0.2vh;
  }
  .yarl__toolbar svg {
    width: 1.2rem;
    height: 1.2rem;
  }
}

@media (max-width: 592px) {
  .yarl__toolbar {
    top: 12%;
    font-size: small;
    padding: 0.3rem;
    gap: 0.2vh;
  }
  .yarl__toolbar svg {
    width: 1rem;
    height: 1rem;
  }
}

@media (max-width: 550px) {
  .yarl__toolbar {
    top: 10%;
    font-size: x-small;
    padding: 0.3rem;
    gap: 0.1vh;
  }

  .yarl__toolbar svg {
    width: 0.8rem; 
    height: 0.8rem;
  }
}

@media (max-width: 480px) {
  .yarl__toolbar {
    top: 5%;
    font-size: x-small;
    padding: 0.3rem;
    gap: 0.1vh;
  }

  .yarl__toolbar svg {
    width: 0.6rem; 
    height: 0.6rem;
  }
}
 